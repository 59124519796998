import Divider from "@mui/material/Divider";
import styles from "./styles.module.css";
import { UserTicket } from "../../../types";

interface Props {
  ticketDetails: any;
  usersPayments: UserTicket[];
}

const TicketMetrics = (props: Props) => {
  const { ticketDetails, usersPayments } = props;
  const calculateTotalPayed = () => {
    const doneUsers = usersPayments.filter(
      (user) => user.payment_status !== "Not paid"
    );
    // function that returns the sum of prices inside selections of each user inside doneUsers
    const totalPayed = doneUsers?.reduce((acc, user) => {
      if (user.selections) {
        const value = user.selections?.reduce((acc, selection) => {
          return acc + Number(selection.price);
        }, 0);
        return acc + value;
      } else {
        return acc;
      }
    }, 0);
    return totalPayed?.toFixed(2);
  };

  return (
    <div className={styles.metricsContainer}>
      <div className={styles.ticketDataContainer}>
        <h2 className={styles.metricName}>Status</h2>
        <h2 className={styles.metricValue}>{ticketDetails?.receipt_state}</h2>
      </div>
      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        sx={{ borderColor: "#fff" }}
      />
      <div className={styles.ticketDataContainer}>
        <h2 className={styles.metricName}>Pagado</h2>
        <h2 className={styles.metricValue}>{calculateTotalPayed()} €</h2>
      </div>
      <Divider
        orientation="vertical"
        variant="middle"
        flexItem
        sx={{ borderColor: "#fff" }}
      />
      <div className={styles.ticketDataContainer}>
        <h2 className={styles.metricName}>Total</h2>
        <h2 className={styles.metricValue}>{ticketDetails?.total_price} €</h2>
      </div>
    </div>
  );
};

export default TicketMetrics;
