import React from "react";
import { UserTicket, WebSocketItems } from "../types";

interface WebSocketProps {
  hash: string | undefined;
  userID: string | null;
  setSelectionError: React.Dispatch<React.SetStateAction<string>>;
  setItems: React.Dispatch<React.SetStateAction<WebSocketItems[]>>;
  setUsers: React.Dispatch<React.SetStateAction<UserTicket[]>>;
  setWebSocket: React.Dispatch<React.SetStateAction<WebSocket | null>>;
  setTicketReady: React.Dispatch<React.SetStateAction<boolean>>;
}

const WebSocketService = (props: WebSocketProps) => {
  const {
    hash,
    userID,
    setSelectionError,
    setItems,
    setUsers,
    setWebSocket,
    setTicketReady,
  } = props;

  // const orderByQuantityDesc = (products: WebSocketItems[]) => {
  //   return products.sort(
  //     (a, b) => parseFloat(b.quantity) - parseFloat(a.quantity)
  //   );
  // };

  const WEBSOCKET_URL = process.env.REACT_APP_WEBSOCKET_URL;
  const url = `${WEBSOCKET_URL}?hash_id=${hash}&user_id=${userID}`; // La dirección del servidor websocket proporcionada por tu compañero backend developer
  const webSocket = new WebSocket(url);

  webSocket.onopen = () => {
    console.log("Conexión establecida con éxito");
  };

  webSocket.onmessage = (event) => {
    try {
      const message = JSON.parse(event.data);
      if (typeof message !== "object" || !message.hasOwnProperty("data")) {
        throw new Error("Invalid message format");
      }
      const { data } = message;
      setTicketReady(message.finish);
      console.log("WebSocket message:", data);

      if (typeof data === "string") {
        setSelectionError(data);
      } else {
        // setItems(orderByQuantityDesc(data.products));
        setItems(data.products);
        setUsers(data.users);
        setSelectionError("");
        return data;
      }
    } catch (error) {
      console.error("WebSocket message error:", error);
    }
  };

  webSocket.onerror = (event) => {
    console.error("WebSocket error:", event);
  };

  webSocket.onclose = (event) => {
    if (event.wasClean) {
      console.log("WebSocket connection closed cleanly");
      setWebSocket(null);
    } else {
      console.warn("WebSocket connection closed with errors:", event);
    }
  };

  setWebSocket(webSocket);

  return () => {
    if (webSocket.readyState === WebSocket.OPEN) {
      webSocket.close();
    }
  };
};

export default WebSocketService;
