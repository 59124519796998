import { TextField, ThemeProvider } from "@mui/material";
import styles from "./styles.module.css";
import { themes } from "../../../styles/themeStyles";

interface Props {
  label: string;
  type: string;
  value: string;
  setValue: (value: React.SetStateAction<string>) => void;
}

const FormField = (props: Props) => {
  const { label, type, value, setValue } = props;

  return (
    <ThemeProvider theme={themes.LoginFieldsTheme}>
      <TextField
        required
        label={label}
        variant="outlined"
        type={type}
        value={value ? value : ""}
        onChange={(event) => setValue(event.target.value)}
        sx={{ width: "100%" }}
        InputLabelProps={{
          sx: { color: "white" },
        }}
        InputProps={{
          sx: {
            color: "white",
            padding: "0.5rem",
          },
          classes: {
            notchedOutline: styles.notchedOutline,
          },
        }}
      />
      <div className={styles.marginM}></div>
    </ThemeProvider>
  );
};

export default FormField;
