import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import "react-html5-camera-photo/build/css/index.css";
import { useParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import QRCode from "qrcode.react";
import { themes } from "../../styles/themeStyles";
import { ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import { Item, UserTicket } from "../../types";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import styles from "./styles.module.css";
import ListItemText from "@mui/material/ListItemText";
import { getTicketDetails, getUrlQR } from "../../services/api";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import logo from "../../assets/images/logo-background-black.svg";
import { AuthContext } from "../../context/AuthProvider";
import { FileCopy } from "@material-ui/icons";
import TicketMetrics from "../../components/features/TicketMetrics/TicketMetrics";
import mixpanel from "mixpanel-browser";
interface TicketDetails {
  created_at: string;
  products: Array<Item>;
  receipt_state: string;
  total_price: string;
  updated_at: string;
  users: Array<UserTicket>;
  validated_products: Array<Item>;
  validated_users: Array<UserTicket>;
}

const TicketDetail = () => {
  const { auth } = useContext(AuthContext);
  const { id: ticketID } = useParams();
  const navigate = useNavigate();
  const [urlQR, setUrlQR] = useState("");
  const [ticketDetails, setTicketDetails] = useState<TicketDetails>();
  const [usersPayments, setUserPayments] = useState<UserTicket[]>([]);
  const [ticketState, setTicketState] = useState<string | undefined>("-");
  const [error, setError] = useState("");
  const [stateCopyLink, setStateCopyLink] = useState(false);

  useEffect(() => {
    // get ticket details
    const getData = () => {
      try {
        getTicketDetails(ticketID).then((data) => {
          setTicketDetails(data);
          setUserPayments(data.users);
          setTicketState(data.receipt_state);
          mixpanel.track("Ticket details received!");
        });
      } catch (e: any) {
        console.error(error);
        setError("Algo salió mal, actualiza la página.");
        mixpanel.track("Error getting ticket details!");
      }
    };

    // get the url to be transformed into a QR
    const getQR = () => {
      try {
        getUrlQR(ticketID).then((data) => {
          setUrlQR(data.url_hash);
          mixpanel.track("Url QR received!");
        });
      } catch (e: any) {
        console.error(error);
        setError("Algo salió mal, actualiza la página.");
        mixpanel.track("Error getting url QR!");
      }
    };

    getData();
    getQR();
  }, []);

  useEffect(() => {
    setTicketState(ticketDetails?.receipt_state);
  }, [ticketDetails]);

  // Aquí puedes hacer una petición a tu API para obtener la información del ticket con id = TicketId
  // y mostrarlo en el componente TicketDetail
  const [showQR, setShowQR] = useState(true);

  const calculateUserTotals = (array: any) => {
    if (array) {
      const total = array.reduce((acc: any, selection: any) => {
        return acc + Number(selection.price);
      }, 0);
      return total?.toFixed(2);
    } else {
      return 0;
    }
  };

  const calculateTotalPayed = () => {
    const doneUsers = usersPayments.filter(
      (user) => user.payment_status !== "Not paid"
    );
    // function that returns the sum of prices inside selections of each user inside doneUsers
    const totalPayed = doneUsers?.reduce((acc, user) => {
      if (user.selections) {
        const value = user.selections?.reduce((acc, selection) => {
          return acc + Number(selection.price);
        }, 0);
        return acc + value;
      } else {
        return acc;
      }
    }, 0);
    return totalPayed?.toFixed(2);
  };

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(urlQR);
  };

  return (
    <div className={styles.ScreenContainer}>
      <div className={styles.dividerM}></div>
      <div className={styles.topButtonsContainer}>
        <ThemeProvider theme={themes.ButtonTheme}>
          <Button
            color="info"
            startIcon={<ArrowBackIosIcon />}
            style={{
              textTransform: "none",
              fontSize: "1rem",
              fontWeight: "600",
              letterSpacing: "0.1rem",
            }}
            onClick={() => {
              mixpanel.track("Ticket Detail to Profile");
              navigate("/profile");
            }}
          >
            Volver
          </Button>
        </ThemeProvider>
      </div>
      <div className={styles.dividerM}></div>
      <TicketMetrics
        ticketDetails={ticketDetails}
        usersPayments={usersPayments}
      />
      {showQR && (
        <div className={styles.shareContainer}>
          <div className={styles.dividerM}></div>

          <div className={styles.QRContainer}>
            <QRCode
              bgColor="#17C3B2"
              fgColor="#080708"
              level="Q"
              value={urlQR}
              renderAs="canvas"
              size={256}
              includeMargin={true}
              className={styles.QR}
            />
            <div className={styles.logoContainer}>
              <img src={logo} alt="Logo" className={styles.logoImg} />
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <ThemeProvider theme={themes.ButtonTheme}>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<FileCopy />}
                style={{
                  textTransform: "none",
                  fontSize: "1rem",
                  fontWeight: "600",
                  letterSpacing: "0.05rem",
                }}
                onClick={() => {
                  setStateCopyLink(true);
                  handleCopyUrl();
                  mixpanel.track("Link copied!");
                  setTimeout(() => {
                    setStateCopyLink(false);
                  }, 2000);
                }}
              >
                {stateCopyLink ? "Copiado!" : "Copiar enlace"}
              </Button>
              <div className={styles.dividerM} />
              <Button
                variant="contained"
                color="primary"
                endIcon={<ArrowForwardIosIcon />}
                style={{
                  textTransform: "none",
                  fontSize: "1rem",
                  fontWeight: "600",
                  letterSpacing: "0.05rem",
                }}
                href={urlQR}
                onClick={() => {
                  mixpanel.track("Ticket detail to Ticket Welcome");
                }}
              >
                Ir al ticket
              </Button>
            </ThemeProvider>
          </div>
          <div className={styles.dividerM} />
        </div>
      )}
      <div className={styles.ticketsContainer}>
        <div className={styles.ticketsHeader}>
          <h2 className={styles.title}>Resumen del ticket</h2>
        </div>
        <List className={styles.ticketsList}>
          {usersPayments.map((user: any) => (
            <ListItem key={user.user_id} className={styles.ticketsListItem}>
              <div className={styles.ticketsListItemContent}>
                <div className={styles.ticketsListItemContentInner}>
                  <h2 className={styles.ticketsListItemState}>
                    {calculateUserTotals(user.selections)} €
                  </h2>
                  <ListItemText
                    primary={user.user_name}
                    secondary={user.payment_status}
                  />
                </div>
                <div className={styles.ticketsListItemIcon}></div>
              </div>
            </ListItem>
          ))}
        </List>
        <div className={styles.dividerXXXL}></div>
      </div>
    </div>
  );
};

export default TicketDetail;
