// import { useEffect, useState } from "react";
// import { AllReceipts } from "../../types";
import logo from "../../assets/images/bill-logo-white.svg";
import { ThemeProvider } from "@mui/material/styles";
import { themes } from "../../styles/themeStyles";
import Button from "@mui/material/Button";
import "../../components/styles.css";
import { useNavigate, useParams } from "react-router-dom";
import styles from "./styles.module.css";
import { useEffect, useState } from "react";
import { getTicketDetails } from "../../services/api";
import { useCookies } from "react-cookie";
import { Item, UserTicket } from "../../types";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import PlusOneIcon from "@mui/icons-material/PlusOne";
import LogoutIcon from "@mui/icons-material/Logout";
import RefreshIcon from "@mui/icons-material/Refresh";
import Logo from "../../components/commons/Logo";
import TicketMetrics from "../../components/features/TicketMetrics";
import mixpanel from "mixpanel-browser";
import Feedback from "../../components/features/Feedback/Feedback";

interface TicketDetails {
  created_at: string;
  products: Array<Item>;
  receipt_state: string;
  total_price: string;
  updated_at: string;
  users: Array<UserTicket>;
  validated_products: Array<Item>;
  validated_users: Array<UserTicket>;
}

const PaymentPage = () => {
  const [cookies, setCookie] = useCookies(["userID", "userName", "receiptID"]);
  const [ticketDetails, setTicketDetails] = useState<TicketDetails>();
  const [usersPayments, setUserPayments] = useState<UserTicket[]>([]);
  const [error, setError] = useState("");
  const [buttonActive, setButtonActive] = useState(false);

  const navigate = useNavigate();

  // get the ticket details
  const getData = () => {
    try {
      getTicketDetails(cookies.receiptID).then((data) => {
        setTicketDetails(data);
        setUserPayments(data.users);
      });
    } catch (e: any) {
      console.error(error);
      setError("Algo salió mal, actualiza la página.");
    }
  };

  useEffect(() => {
    getData();
    lastUpdateTime();
  }, []);

  const calculateUserTotals = (array: any) => {
    if (array) {
      const total = array.reduce((acc: any, selection: any) => {
        return acc + Number(selection.price);
      }, 0);
      return total?.toFixed(2);
    } else {
      return ""
    }
  };

  const lastUpdateTime = () => {
    // get current time
    const date = new Date();
    // get the hours and minutes
    const hours = date.getHours();
    const minutes = date.getMinutes();
    // return the time
    return `${hours}:${minutes < 10 ? "0" + minutes : minutes}`;
  };

  return (
    <div className={styles.ScreenContainer}>
      <Logo />
      {/* header */}
      <div className={styles.titleContainer}>
        <p className={styles.title}>Resumen de la cuenta</p>
        <p className={styles.selectionSubtitle}>
          ¿Te gustaría pagar desde esta app? ¡Vota!
        </p>
      </div>
      <div className={styles.topButtonsBox}>
        {/* header */}
        <Feedback page="payment" />
        <ThemeProvider theme={themes.VoteButtonTheme}>
          <Button
            variant="contained"
            color={!buttonActive ? "secondary" : "primary"}
            startIcon={<PlusOneIcon />}
            onClick={() => {
              mixpanel.track("User wants to pay here");
              setButtonActive(!buttonActive);
            }}
            style={{
              textTransform: "none",
              fontSize: "1rem",
              fontWeight: "600",
              letterSpacing: "0.1rem",
            }}
          >
            {!buttonActive ? "¡Votar!" : "Hemos recibido tu voto!"}
          </Button>
        </ThemeProvider>
      </div>

      <div className={styles.dividerS}></div>
      <TicketMetrics
        ticketDetails={ticketDetails}
        usersPayments={usersPayments}
      />
      <div className={styles.dividerS}></div>
      <div className={styles.ticketsContainer}>
        <div className={styles.ticketsHeader}>
          <h2 className={styles.ticketsTitle}>Tickets</h2>
          <div className={styles.dividerS}></div>
          <Button
            variant="text"
            color="primary"
            startIcon={<RefreshIcon />}
            onClick={() => {
              mixpanel.track("Data updated");
              getData();
            }}
            style={{
              textTransform: "none",
              padding: "0",
            }}
          >
            Actualizar
          </Button>
        </div>
        <p className={styles.lastUpdate}>
          Última actualización a las {lastUpdateTime()}
        </p>
        <div className={styles.dividerM}></div>
        <ThemeProvider theme={themes.ButtonTheme}>
          <Button
            variant="contained"
            color="primary"
            startIcon={<LogoutIcon />}
            style={{
              textTransform: "none",
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              letterSpacing: "0.1rem",
            }}
            onClick={() => {
              mixpanel.track("Marked as paid and exited");
              navigate(`/`);
            }}
          >
            Marcar como pagado y salir
          </Button>
        </ThemeProvider>
        <div className={styles.dividerM}></div>
        <List className={styles.ticketsList}>
          {usersPayments.map((user: any) => (
            <ListItem key={user?.user_id} className={styles.ticketsListItem}>
              <div className={styles.ticketsListItemContent}>
                <div className={styles.ticketsListItemContentInner}>
                  <h2 className={styles.ticketsListItemState}>
                    {calculateUserTotals(user?.selections)} €
                  </h2>
                  <ListItemText
                    primary={user?.user_name}
                    secondary={user?.payment_status}
                  />
                </div>
                <div className={styles.ticketsListItemIcon}></div>
              </div>
            </ListItem>
          ))}
        </List>
        <div className={styles.dividerXXXL}></div>
      </div>
    </div>
  );
};

export default PaymentPage;
