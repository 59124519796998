import * as React from "react";
import "../../assets/css/main.css";
import "../../assets/css/noscript.css";
import "../../assets/css/fontawesome-all.min.css";
import pic_scan from "../../assets/images/scan.jpg";
import pic_selection from "../../assets/images/selection.jpg";
import pic_calculator from "../../assets/images/calculator.jpg";
import pic_confirmation from "../../assets/images/confirmation.jpg";
import logo from "../../assets/images/bill-logo-white.svg";
import { useNavigate } from "react-router-dom";
import { Button, ThemeProvider } from "@mui/material";
import { themes } from "../../styles/themeStyles";
import styles from "./styles.module.css";

const Index = () => {
  const navigate = useNavigate();

  const contactUS = () => {
    window.open(
      "https://calendly.com/hector-ydt/talk-with-hector?month=2023-05",
      "_blank"
    );
  };

  return (
    <div className={styles.mainContainer}>
      <div className={`${styles.landing} ${styles.isPreload}`}>
        <header id="header" className="alt">
          <h1>
            <a href="/">BiLL</a>
          </h1>
          <nav id="nav"></nav>
        </header>
        <section
          id="banner"
          style={{ letterSpacing: "0.225em", textTransform: "uppercase" }}
        >
          <div className="overlay"></div>
          <div className="innerHero" style={{ zIndex: 2 }}>
            <img
              src={logo}
              alt="Logo"
              style={{
                width: "5rem",
                height: "5rem",
                justifyItems: "center",
              }}
            />
            <div style={{ margin: "1rem " }}></div>
            <p style={{ color: "white" }}>
              La forma más fácil y rápida de hacer las cuentas con tus amigos.
            </p>
            <div style={{ margin: "1rem " }}></div>
            <div className="buttonContainer">
              <ThemeProvider theme={themes.ButtonTheme}>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    textTransform: "none",
                    fontSize: "1rem",
                    fontWeight: "600",
                    width: "100%",
                    letterSpacing: "0.1rem",
                  }}
                  onClick={() => navigate("login")}
                >
                  Iniciar sesión
                </Button>
                <div style={{ margin: "0.5rem " }}></div>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: "none",
                    fontSize: "1rem",
                    fontWeight: "600",
                    width: "100%",
                    letterSpacing: "0.1rem",
                  }}
                  onClick={() => navigate(`/join`)}
                >
                  Crea tu cuenta
                </Button>
              </ThemeProvider>
            </div>
          </div>
        </section>

        <section id="one" className="wrapper style1 special">
          <div className="inner">
            <header className="major">
              <h2>
                ¿Quieres saber lo que realmente tienes que pagar de un ticket?
              </h2>
              <p
                style={{
                  fontSize: "1rem",
                  lineHeight: "1.4rem",
                  fontWeight: 500,
                  letterSpacing: "0.1rem",
                }}
              >
                La app de BiLL te permite subir un ticket, seleccionar los
                productos que vas a pagar y obtener el importe final,{" "}
                <b>totalmente gratis y sin descargar ninguna app.</b>
              </p>
            </header>
            <ul className="icons major">
              <li>
                <span className="icon fa-gem major style1">
                  <span className="label">Lorem</span>
                </span>
              </li>
              <li>
                <span className="icon fa-heart major style2">
                  <span className="label">Ipsum</span>
                </span>
              </li>
              <li>
                <span className="icon solid fa-code major style3">
                  <span className="label">Dolor</span>
                </span>
              </li>
            </ul>
          </div>
        </section>

        <section id="two" className="wrapper alt style2">
          <section className="spotlight">
            <div className="image">
              <img src={pic_scan} alt="" />
            </div>
            <div className="content">
              <h2>Sube el ticket a BiLL</h2>
              <p>
                Obtén el código QR para acceder al ticket en menos de 30
                segundos, tan solo es necesaria una cuenta de BiLL y hacer una
                foto al ticket a través de la herramienta.
              </p>
            </div>
          </section>
          <section className="spotlight">
            <div className="image">
              <img src={pic_selection} alt="" />
            </div>
            <div className="content">
              <h2>¿Junto o por separado?</h2>
              <p>
                Al marcar cada producto, podrás indicar las unidades que quieres
                pagar o si pagaréis el producto entre varias personas.
              </p>
            </div>
          </section>
          <section className="spotlight">
            <div className="image">
              <img src={pic_calculator} alt="" />
            </div>
            <div className="content">
              <h2>Adiós a la calculadora</h2>
              <p>
                BiLL calculará por ti todas las sumas y divisiones que necesites
                para que no tengas que recurrir a la calculadora. Cuando todos
                los usuarios hayan hecho su selección, la app os dará los
                importes definitivos de cada uno.
              </p>
            </div>
          </section>
        </section>
        <section id="three" className="wrapper style3 special">
          <div className="inner">
            <header className="major">
              <h2>Olvídate del drama a la hora de pagar</h2>
              <p>
                Muchas veces todo ha ido perfecto, pero llega el momento de
                decidir cómo se va a pagar la cuenta y empiezan a surgir los
                problemas... ¡Simplifica este trámite para siempre con BiLL!
              </p>
            </header>
            <ul className="features">
              <li className="icon fa-flag">
                <h3>sin descargar app</h3>
                <p>
                  Sube un ticket directamente desde la web de BiLL después de
                  iniciar sesión.
                </p>
              </li>
              <li className="icon fa-paper-plane">
                <h3>Gratuito</h3>
                <p>
                  El uso de la herramienta es totalmente gratuito y puedes subir
                  tantos tickets como quieras.
                </p>
              </li>
              <li className="icon fa-heart">
                <h3>Fácil</h3>
                <p>
                  Conoce el importe de tu selección en segundos y sin esfuerzo.
                </p>
              </li>
              <li className="icon solid fa-exclamation-circle">
                <h3>Sin errores de cálculo</h3>
                <p>
                  Evita que las cuentas no cuadren y obtén el importe exacto de
                  lo que vas a pagar.
                </p>
              </li>
            </ul>
          </div>
        </section>
        <section id="cta" className="wrapper style4">
          <div className="inner">
            <header>
              <h2>¡Crea tu cuenta ya!</h2>
              <p>
                Completa el formulario con los datos de tu negocio y empieza a
                utilizar la app de BiLL esta misma semana.
              </p>
            </header>
            <div style={{ margin: "1rem " }}></div>
            <div className="buttonContainer">
              <ThemeProvider theme={themes.ButtonTheme}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: "none",
                    fontSize: "1rem",
                    fontWeight: "600",
                    width: "100%",
                    letterSpacing: "0.1rem",
                  }}
                  onClick={() => navigate("join")}
                >
                  Crea tu cuenta
                </Button>
                <div style={{ margin: "0.5rem" }}></div>
                <Button
                  variant="outlined"
                  color="primary"
                  style={{
                    textTransform: "none",
                    fontSize: "1rem",
                    fontWeight: "600",
                    width: "100%",
                    letterSpacing: "0.1rem",
                  }}
                  onClick={contactUS}
                >
                  Contáctanos
                </Button>
              </ThemeProvider>
            </div>
          </div>
        </section>
        <footer id="footer">
          {/* <ul className="icons">
            <li>
              <a href="#" className="icon brands fa-twitter">
                <span className="label">Twitter</span>
              </a>
            </li>
            <li>
              <a href="#" className="icon brands fa-facebook-f">
                <span className="label">Facebook</span>
              </a>
            </li>
            <li>
              <a href="#" className="icon brands fa-instagram">
                <span className="label">Instagram</span>
              </a>
            </li>
            <li>
              <a href="#" className="icon brands fa-dribbble">
                <span className="label">Dribbble</span>
              </a>
            </li>
            <li>
              <a href="#" className="icon solid fa-envelope">
                <span className="label">Email</span>
              </a>
            </li>
          </ul> */}
          <ul className="copyright">
            <li>&copy; 2023 by YDT DATA CONSULTING SL. All rights reserved.</li>
          </ul>
        </footer>
      </div>
    </div>
  );
};

export default Index;
