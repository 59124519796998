import React from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Ticket } from "../../../types";
import { useNavigate } from "react-router-dom";
import styles from "./styles.module.css";

interface Props {
  tickets: Array<Ticket>;
}

const TicketList: React.FC<Props> = (props) => {
  const { tickets } = props;

  const navigate = useNavigate();

  const handleItemClick = (id: string) => {
    navigate(`ticket-detail/${id}`);
  };

  const formatedDate = (datetime: string) => {
    const currentDate = new Date();
    const createdDate = new Date(datetime);
    const daysDiff = Math.floor(
      (currentDate.getTime() - createdDate.getTime()) / (1000 * 3600 * 24)
    );

    const formattedDate = `${
      createdDate.getHours() < 10
        ? "0" + createdDate.getHours()
        : createdDate.getHours()
    }:${
      createdDate.getMinutes() < 10
        ? "0" + createdDate.getMinutes()
        : createdDate.getMinutes()
    } - ${daysDiff} días`;
    return formattedDate;
  };

  return (
    <div className={styles.ticketsContainer}>
      <div className={styles.ticketsHeader}>
        <h3 className={styles.title}>Últimos tickets</h3>
      </div>
      <List className={styles.ticketsList}>
        {tickets.map((row) => (
          <ListItem key={row.receipt} className={styles.ticketsListItem}>
            <div
              className={styles.ticketsListItemContent}
              onClick={() => handleItemClick(row.receipt)}
            >
              <div className={styles.ticketsListItemContentInner}>
                <h2 className={styles.ticketsListItemState}>
                  {Number(row.total_price).toFixed(2)} €
                </h2>
                <ListItemText
                  primary={row.receipt_state}
                  secondary={formatedDate(row.created_at)}
                  className={styles.ticketsListItemText}
                />
              </div>
              <div className={styles.ticketsListItemIcon}>
                <ListItemIcon sx={{ justifyContent: "flex-end" }}>
                  <ArrowForwardIosIcon />
                </ListItemIcon>
              </div>
            </div>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default TicketList;
