import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CircularProgress, ThemeProvider } from "@mui/material";
import Button from "@mui/material/Button";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { themes } from "../../styles/themeStyles";
import styles from "./styles.module.css";
import { Alert } from "@mui/material";
import { useCookies } from "react-cookie";
import { createUserInDynamo, signUpConfirmation } from "../../services/api";
import FormField from "../../components/commons/FormField";
import Logo from "../../components/commons/Logo";
import mixpanel from "mixpanel-browser";

const VerificationCodePage = () => {
  const [cookies] = useCookies([
    "email",
    "name",
    "verificationCode",
  ]);
  const navigate = useNavigate();
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(false);

  // Send data to server and save the userId from response
  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await signUpConfirmation(
        cookies.email,
        verificationCode
      );
      mixpanel.track("Verification code sent", {
        email: cookies.email,
        verificationCode: verificationCode,
        is_correct: response,
      });
      if (response === true) {
        try {
          const response = await createUserInDynamo(
            cookies.email,
            cookies.name
          );
          if (response === true) {
            mixpanel.track("User created successfully", {
              email: cookies.email,
              name: cookies.name,
            });
            navigate(`/login`);
          }
        } catch (error) {
          setError("Código de verificación incorrecto");
          mixpanel.track("User creation failed");
        }
      }
    } catch (error) {
      setError("Código de verificación incorrecto:");
    }
    setLoading(false);
  };

  return (
    <div className={styles.ScreenContainer}>
      <Logo />
      <div>
        <h1>Valida tu cuenta</h1>
      </div>
      <div className={styles.InfoContainer}>
        <p className={styles.InfoText}>
          Introduce el código de verificación que hemos enviado a tu email.
        </p>
      </div>
      <div className={styles.marginS}></div>
      {error && (
        <div className={styles.alertContainer}>
          <Alert severity="error">{error}</Alert>
        </div>
      )}
      <div className={styles.loginFieldsContainer}>
        <FormField
          label="Código de verificación"
          type="number"
          value={verificationCode}
          setValue={setVerificationCode}
        />
      </div>
      <div className={styles.dividerM} />
      {loading ? (
        <div className={styles.LoadingContainer}>
          <CircularProgress size={30} sx={{ color: "#1de9b6" }} />
        </div>
      ) : (
        <ThemeProvider theme={themes.ButtonTheme}>
          <Button
            variant="contained"
            color="primary"
            endIcon={<ArrowForwardIosIcon />}
            style={{
              textTransform: "none",
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              letterSpacing: "0.1rem",
            }}
            onClick={handleSubmit}
          >
            Siguiente
          </Button>
        </ThemeProvider>
      )}
      <div className={styles.dividerL} />
    </div>
  );
};

export default VerificationCodePage;
