import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useContext, useEffect } from "react";
import { AuthContext } from "../../../context/AuthProvider";

const RequireAuth = () => {
  const { auth } = useContext(AuthContext);

  const location = useLocation();

  return auth?.customerID ? (
    <Outlet />
  ) : (
    <Navigate to="/" state={{ from: location }} replace />
  );
};

export default RequireAuth;