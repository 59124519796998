import { createTheme, makeStyles } from "@material-ui/core/styles";

const ButtonTheme = createTheme({
  palette: {
    primary: {
      main: "#1de9b6",
    },
    secondary: {
      main: "#121212",
    },
    info: {
      main: "#fff",
    },
  },
});

const VoteButtonTheme = createTheme({
  palette: {
    primary: {
      main: "#1de9b6",
    },
    secondary: {
      main: "#ffffffe0",
    },
    info: {
      main: "#fff",
    },
  },
});

const LoginFieldsTheme = createTheme({
  palette: {
    common: {
      white: "#fff",
    },
    primary: {
      main: "#1de9b6",
    },
  },
  overrides: {
    MuiFormLabel: {
      root: {
        color: "#fff",
        "& .MuiInputLabel-root": {
          color: "#fff",
        },
      },
    },
    MuiInputBase: {
      root: {
        color: "#fff",
        "&$focused": {
          color: "#1de9b6",
        },
        "&.Mui-focused": {
          backgroundColor: "transparent",
        },
        "&.MuiOutlinedInput-input": {
          color: "#fff",
          padding: "0.5rem",
        },
        "& .MuiOutlinedInput-notchedOutline": {
          borderColor: "#fff",
        },
        "&:hover:not($disabled):not($focused):not($error) .MuiOutlinedInput-notchedOutline":
          {
            borderColor: "#fff",
          },
        "&$focused .MuiOutlinedInput-notchedOutline": {
          borderColor: "#1de9b6",
        },
        "& .MuiInputBase-input": {
          "&:-webkit-autofill": {
            boxShadow: "none",
            WebkitBoxShadow: "none",
          },
        },
      },
      input: {
        color: "#fff",
        "&.MuiOutlinedInput-input:-webkit-autofill": {},
      },
    },
    MuiOutlinedInput: {
      root: {
        color: "#fff",
        "& $notchedOutline": {
          borderColor: "#fff",
          color: "#fff",
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "#fff",
          color: "#fff",
        },
        "&$focused $notchedOutline": {
          borderColor: "#1de9b6",
          color: "#fff",
        },
      },
      input: {
        "&:-webkit-autofill": {
          boxShadow: "none",
          WebkitBoxShadow: "none",
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: "#fff",
        "&:hover": {
          color: "#fff",
        },
      },
    },
    MuiFormHelperText: {
      root: {
        color: "#fff",
      },
    },
    MuiInputAdornment: {
      root: {
        color: "#fff",
      },
    },
  },
});

export const themes = {
  ButtonTheme: ButtonTheme,
  LoginFieldsTheme: LoginFieldsTheme,
  VoteButtonTheme: VoteButtonTheme,
};