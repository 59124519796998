import logo from "../../../assets/images/bill-logo-white.svg";
import styles from "./styles.module.css";

const Logo = () => {
  return (
    <div className={styles.logoContainer}>
      <img 
      src={logo} 
      alt="Logo" 
      className={styles.logo} />
    </div>
  );
};

export default Logo;
