import { useState } from "react";
import useAuth from "../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/bill-logo-white.svg";
import { ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { themes } from "../../styles/themeStyles";
import styles from "./styles.module.css";
import { Alert, CircularProgress } from "@mui/material";
import { useCookies } from "react-cookie";
import { resetPassword } from "../../services/api";
import FormField from "../../components/commons/FormField";
import Logo from "../../components/commons/Logo";
import mixpanel from "mixpanel-browser";

const ResetPasswordPage = () => {
  const [cookies, setCookie] = useCookies(["email"]);
  const { setAuth } = useAuth();
  const navigate = useNavigate();
  const [customerEmail, setCustomerEmail] = useState<string>(cookies.email ? cookies.email : "");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState("");
  const [verificationCode, setVerificationCode] = useState<string>("");
  const [loading, setLoading] = useState(false);

  // Send data to server and save the userId from response

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await resetPassword(
        customerEmail,
        verificationCode,
        password
      );
      if (response === true) {
        mixpanel.track("Password changed succesfully");
        navigate("/profile");
      }
    } catch (error: any) {
      setError(error.response.data.message);
      mixpanel.track("Error changing password");
    }
    setLoading(false);
  };

  return (
    <div className={styles.ScreenContainer}>
      <Logo />
      <div>
        <h1>Reiniciar contraseña</h1>
      </div>
      <div className={styles.marginS}></div>
      {error && (
        <div className={styles.alertContainer}>
          <Alert severity="error">{error}</Alert>
        </div>
      )}
      <div className={styles.loginFieldsContainer}>
        <FormField
          label="Email"
          type="email"
          value={customerEmail}
          setValue={setCustomerEmail}
        />
        <FormField
          label="Código de verificación"
          type="number"
          value={verificationCode}
          setValue={setVerificationCode}
        />
        <FormField
          label="Nueva contraseña"
          type="password"
          value={password}
          setValue={setPassword}
        />
        <div className={styles.dividerL} />
      </div>
      {loading ? (
        <div className={styles.LoadingContainer}>
          <CircularProgress size={30} sx={{ color: "#1de9b6" }} />
        </div>
      ) : (
        <ThemeProvider theme={themes.ButtonTheme}>
          <Button
            variant="contained"
            color="primary"
            endIcon={<ArrowForwardIosIcon />}
            style={{
              textTransform: "none",
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              letterSpacing: "0.1rem",
            }}
            onClick={handleSubmit}
          >
            Reiniciar contraseña
          </Button>
        </ThemeProvider>
      )}
      <div className={styles.dividerL} />
    </div>
  );
};

export default ResetPasswordPage;
