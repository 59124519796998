import React from "react";

const CameraGrid: React.FC = () => {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        display: "grid",
        gridTemplateColumns: "0.5fr 1fr 0.5fr",
        gridTemplateRows: "0.5fr 1fr 0.5fr",
        gridColumnGap: 0,
        gridRowGap: 0,
        zIndex: 1,
      }}
    >
      {[1, 2, 3, 4, 5, 6, 7, 8, 9].map((cell, index) => (
        <div
          key={index}
          style={{
            backgroundColor: "transparent",
            border: "1px solid white",
            width: "100%",
            height: "100%",
            opacity: 0.3,
          }}
        />
      ))}
    </div>
  );
};

export default CameraGrid;
