import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { themes } from "../../styles/themeStyles";
import styles from "./styles.module.css";
import { Alert, CircularProgress } from "@mui/material";
import { useCookies } from "react-cookie";
import { signUp } from "../../services/api";
import FormField from "../../components/commons/FormField";
import Logo from "../../components/commons/Logo";
import mixpanel from "mixpanel-browser";

const SignUpPage = () => {
  const [cookies, setCookie] = useCookies(["email", "name"]);
  const navigate = useNavigate();
  const [userName, setUserName] = useState<string>("");
  const [customerEmail, setCustomerEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [error, setError] = useState<string | undefined>("");
  const [loading, setLoading] = useState(false);

  // Send data to server and save the userId from response
  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await signUp(customerEmail, password);
      if (response === true) {
        setCookie("email", customerEmail, { path: "/" });
        setCookie("name", userName, { path: "/" });
        mixpanel.track("Credentials sended successfully");
        navigate(`/verification-code`);
      } else {
        setError(response);
        mixpanel.track("Error sending credentials");
        setTimeout(() => {
          setError("");
        }, 5000);
      }
    } catch (error) {
      setError("Ha ocurrido un error, inténtalo de nuevo.");
    }
    setLoading(false);
  };

  return (
    <div className={styles.ScreenContainer}>
      <Logo />
      <div>
        <h1>Únete a BiLL</h1>
      </div>
      <div className={styles.InfoContainer}>
        <p className={styles.InfoText}>Crea una cuenta para crear tus bills.</p>
      </div>
      <div className={styles.marginS}></div>
      {error && (
        <div className={styles.alertContainer}>
          <Alert severity="error">{error}</Alert>
        </div>
      )}
      <div className={styles.loginFieldsContainer}>
        <FormField
          label="Nombre"
          type="text"
          value={userName}
          setValue={setUserName}
        />
        <FormField
          label="Email"
          type="email"
          value={customerEmail}
          setValue={setCustomerEmail}
        />
        <FormField
          label="Contraseña"
          type="password"
          value={password}
          setValue={setPassword}
        />
      </div>
      <p className={styles.disclaimer}>
        Al hacer click en Confirmar, aceptas nuestros{" "}
        <a
          href="/terminos-y-condiciones"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.disclaimerLink}
        >
          términos y condiciones
        </a>{" "}
        y la{" "}
        <a
          href="/politica-privacidad"
          target="_blank"
          rel="noopener noreferrer"
          className={styles.disclaimerLink}
        >
          política de privacidad
        </a>
        .
      </p>
      <div className={styles.dividerM} />
      {loading ? (
        <div className={styles.LoadingContainer}>
          <CircularProgress size={30} sx={{ color: "#1de9b6" }} />
        </div>
      ) : (
        <ThemeProvider theme={themes.ButtonTheme}>
          <Button
            variant="contained"
            color="primary"
            endIcon={<ArrowForwardIosIcon />}
            style={{
              textTransform: "none",
              fontSize: "1rem",
              fontWeight: "600",
              width: "100%",
              letterSpacing: "0.1rem",
            }}
            onClick={handleSubmit}
          >
            Confirmar
          </Button>
        </ThemeProvider>
      )}
      <div className={styles.dividerL} />
      <div className={styles.LogInContainer}>
        <p className={styles.InfoText}>
          Ya tienes una cuenta? {"         "}
          <a
            className={styles.logInLink}
            href="/login"
            onClick={() => {
              mixpanel.track("Signup to Login");
            }}
          >
            Inicia Sesión
          </a>
        </p>
      </div>
    </div>
  );
};

export default SignUpPage;
