import React, { useEffect, useState } from "react";
import { Item, Selection } from "../../../types";
import "../../../components/styles.css";
import QuantityCounter from "../QuantityCounter/QuantityCounter";
import Button from "@mui/material/Button";
import StyledBadge from "../StyledBadge/StyledBadge";
import styles from "./styles.module.css";
import SafetyDividerIcon from "@mui/icons-material/SafetyDivider";
import AddIcon from "@mui/icons-material/Add";
import mixpanel from "mixpanel-browser";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";

interface ItemRowProps {
  item: Item;
  handleChange: (
    itemID: string | undefined,
    selectionMode: string,
    value: number,
    description: string,
    unit_price: string
  ) => void;
  userReady: boolean;
  currentSelection: Selection[] | [];
}

const ItemSelectionRow = (props: ItemRowProps) => {
  const { item, handleChange, currentSelection, userReady } = props;

  const [selectedMethod, setSelectedMethod] = useState("");
  const [containerSelected, setContainerSelected] = useState<boolean>(false);
  const [showMethod, setShowMethod] = useState<boolean>(false);
  const [quantity, setQuantity] = useState<number>(0);

  useEffect(() => {
    if (currentSelection.length > 0) {
      setSelectedMethod(currentSelection[0].selection_mode);
      setContainerSelected(true);
      setCount(getStepSize(Number(currentSelection[0].quantity)));
      setQuantity(Number(currentSelection[0].quantity));
    } else {
      setSelectedMethod("");
      setContainerSelected(false);
      setCount(getStepSize(Number(item.quantity)));
      setQuantity(0);
    }
  }, []);

  ///////////  Counter  ///////////

  // Devuelve el tamaño de los incrementales del counter en base a los
  // decimales de la cantidad del item
  const getStepSize = (number: number) => {
    const decimalPlaces = (number.toString().split(".")[1] || "").length;
    return 1 / Math.pow(10, decimalPlaces);
  };

  // Cantidad by default = stepSize
  const [count, setCount] = useState(
    currentSelection.length > 0
      ? Number(currentSelection[0].quantity)
      : getStepSize(Number(item.quantity))
  );

  // Devuelve el tamaño de los incrementales del counter en base a los
  // decimales de la cantidad del item
  const containerStyle = () => {
    if (item.quantity === "0.0" && currentSelection.length === 0) {
      return styles.itemContainerDisabled;
    } else if (containerSelected) {
      return styles.selectedItemContainer;
    } else {
      return styles.itemContainer;
    }
  };

  ///////////  Save & Delete  ///////////

  const handleSaveClick = (selectionMode: string) => {
    let quantity = 0;
    if (selectionMode === "unit") {
      quantity = count;
      setQuantity(quantity);
    } else {
      quantity = 1;
      setQuantity(1);
    }
    handleChange(
      item.product_id,
      selectionMode,
      quantity,
      item.description,
      item.unit_price
    );
    setShowMethod(false);
    setContainerSelected(true);
    mixpanel.track("Selection saved", {
      "selection mode": selectionMode,
    });
  };

  const handleDeleteClick = () => {

    if (currentSelection.length > 0) {
      handleChange(
        currentSelection[0].product_id,
        currentSelection[0].selection_mode,
        quantity * -1,
        currentSelection[0].description,
        currentSelection[0].unit_price
      );
    }
    setContainerSelected(false);
    setSelectedMethod("");
    setCount(getStepSize(Number(item.quantity)));
    setQuantity(0);
    setShowMethod(false);
    mixpanel.track("Selection deleted");
  };

  const updatedPrice = () => {
    const updated_price = Number(item.quantity) * Number(item.unit_price);
    return updated_price.toFixed(2);
  }

  return (
    <div className={containerStyle()}>
      <StyledBadge
        badgeContent={
          item.quantity.endsWith(".0")
            ? item.quantity.replace(".0", "")
            : item.quantity
        }
        anchorOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <div
          className={styles.rowItemsTable}
          onClick={(e) => {
            (item.quantity !== "0.0" || currentSelection.length !== 0) &&
              setShowMethod(!showMethod);
          }}
        >
          <p className={styles.itemMiddle}>{item.description}</p>
          <p className={styles.itemLateral}>{updatedPrice()} €</p>
        </div>
      </StyledBadge>
      <div className={styles.dividerS} />
      {!userReady && (
        <div style={{ width: "100%" }}>
          <div className={styles.buttonsContainer}>
            {selectedMethod === "unit" && (
              <div className={styles.buttonsContainer}>
                {quantity === 0 && (
                  <Button
                    variant="text"
                    color="inherit"
                    onClick={() => setSelectedMethod("")}
                    startIcon={<ArrowBackIosIcon />}
                    sx={{ color: "gray" }}
                  ></Button>
                )}
                <QuantityCounter
                  value={quantity !== 0 ? quantity : count}
                  stepSize={getStepSize(Number(item.quantity))}
                  maxValue={item.quantity}
                  onChange={setCount}
                />
                {quantity !== 0 ? (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleDeleteClick}
                    style={{
                      textTransform: "none",
                      fontSize: "0.9rem",
                      fontWeight: "500",
                      width: "100%",
                      letterSpacing: "0.05rem",
                      background: "#ed5757",
                    }}
                  >
                    Borrar
                  </Button>
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleSaveClick("unit")}
                    style={{
                      textTransform: "none",
                      fontSize: "0.9rem",
                      fontWeight: "500",
                      width: "100%",
                      letterSpacing: "0.05rem",
                    }}
                  >
                    Guardar
                  </Button>
                )}
              </div>
            )}
            {selectedMethod !== "unit" && (
              <div className={styles.buttonsContainer}>
                <Button
                  variant={selectedMethod === "shared" ? "contained" : "text"}
                  color="primary"
                  onClick={() => {
                    if (selectedMethod === "") {
                      setSelectedMethod("shared");
                      handleSaveClick("shared");
                    } else {
                      setSelectedMethod("");
                      handleDeleteClick();
                    }
                  }}
                  style={{
                    textTransform: "none",
                    fontSize: "0.9rem",
                    fontWeight: "500",
                    width: "100%",
                    letterSpacing: "0.05rem",
                  }}
                  startIcon={<SafetyDividerIcon />}
                >
                  {selectedMethod === "shared"
                    ? "Compartiendo"
                    : "Compartir pago"}
                </Button>
                <Button
                  disabled={selectedMethod === "shared" ? true : false}
                  variant="text"
                  color="primary"
                  onClick={() => setSelectedMethod("unit")}
                  style={{
                    textTransform: "none",
                    fontSize: "0.9rem",
                    fontWeight: "500",
                    width: "100%",
                    letterSpacing: "0.05rem",
                  }}
                  startIcon={<AddIcon />}
                >
                  Seleccionar
                </Button>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default ItemSelectionRow;
