import axios from "axios";
import { Item, WebSocketItems, Selection } from "../types";

interface Identification {
  url_hash: string | undefined;
  user_name: string;
}
const API_BASE_URL = process.env.REACT_APP_API_URL;
const COGNITO_URL = process.env.REACT_APP_COGNITO_URL;
const COGNITO_CLIENT_ID = process.env.REACT_APP_COGNITO_CLIENT_ID;

// Indentify user at Welcome Page
export async function createIdentification(
  hash: string | undefined,
  name: string,
  setErrorMessage: React.Dispatch<React.SetStateAction<string>>
): Promise<string | undefined> {
  const identification: Identification = {
    url_hash: hash,
    user_name: name,
  };

  try {
    const response = await axios.post(
      `${API_BASE_URL}/receipts/${hash}/users/`,
      identification
    );
    const userID = response.data.data.user_id;
    return userID;
  } catch (error: any) {
    console.error(error);
    if (error.response.data.code === "User name") {
      setErrorMessage("Este nombre ya ha sido escogido.");
    } else {
      setErrorMessage("Ha ocurrido un error, inténtalo de nuevo.");
    }
    throw new Error("Error creating identification");
  }
}

// Create a new user in cognito
export async function signUp(email: string, password: string) {
  const credentials = {
    email: email,
    password: password,
  };

  try {
    const response = await axios.post(`${API_BASE_URL}/signup`, credentials);

    if (response.status === 200) {
      return true;
    }
  } catch (error: any) {
    console.error(error);
    const errorMessage = error.response.data.messages["message:"].Error;
    return "Ha ocurrido un error, inténtalo de nuevo.";
  }
  
}

// Confirm account with the code sended to the email
export async function signUpConfirmation(
  email: string,
  confirmation_code: string
) {
  const credentials = {
    email: email,
    confirmation_code: confirmation_code,
  };

  try {
    const response = await axios.post(
      `${API_BASE_URL}/signup/confirmation`,
      credentials
    );
    if (response.status === 200) {
      return true;
    }
  } catch (error: any) {
    console.error(error);
  }
}

// Creates a user in dynamo
export async function createUserInDynamo(email: string, name: string) {
  const body = {
    email: email,
    name: name,
  };

  try {
    const response = await axios.post(`${API_BASE_URL}/customers`, body);
    if (response.status === 200) {
      return true;
    }
  } catch (error: any) {
    console.error(error);
  }
}

export async function forgotPassword(email: string) {
  const credentials = {
    email: email,
  };

  try {
    const response = await axios.post(
      `${API_BASE_URL}/forgot-password`,
      credentials
    );
    if (response.status === 200) {
      return true;
    }
  } catch (error: any) {
    console.error(error);
  }
}

export async function resetPassword(
  email: string,
  confirmation_code: string,
  password: string
) {
  const credentials = {
    email: email,
    confirmation_code: confirmation_code,
    password: password,
  };

  try {
    const response = await axios.post(
      `${API_BASE_URL}/forgot-password/confirmation`,
      credentials
    );
    if (response.status === 200) {
      return true;
    }
  } catch (error: any) {
    console.error(error);
  }
}

export async function postSelections(
  hash: string | undefined,
  userID: string | null,
  object: {
    selections: Selection[];
    selection_type: string;
  }
) {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/receipts/${hash}/users/${userID}/products`,
      JSON.stringify(object)
    );
    if (response.status === 200) {
      const data = response.data.data;
      return true;
    }
  } catch (error: any) {
    console.error(error);
  }
}

//
export async function getTicketProducts(
  hash: string | undefined,
  setItems: React.Dispatch<React.SetStateAction<WebSocketItems[]>>
) {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/receipts/${hash}/products`
    );

    if (response.status === 200) {
      // guarda los tickets
      const data = response.data.data;
      setItems(data.products);
    }
  } catch (error) {
    console.error(error);
  }
}

// get receipts for all users
export async function getUserReceipts(
  hash: string | undefined,
  userID: string | null
): Promise<any> {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/receipts/${hash}/users/${userID}/products`
    );

    if (response.status === 200) {
      const allReceipts = response.data.data;
      return allReceipts;
    }
  } catch (error) {
    console.error(error);
  }

  return undefined;
}

// Send final receipt
export async function postFinalReceipt(
  hash: string | undefined,
  userID: string | null,
  object: {
    selections: any;
    selection_type: string;
  }
) {
  try {
    const response = await axios.put(
      `${API_BASE_URL}/receipts/${hash}/users/${userID}/products`,
      JSON.stringify(object)
    );
    const data = response.data.data;
    return true;
  } catch (error: any) {
    console.error(error);
    return false;
  }
}

// Get data from Customer
export async function getCustomerDetails(customerID: string | undefined) {
  try {
    const response = await axios.get(`${API_BASE_URL}/customers/${customerID}`);

    if (response.status === 200) {
      const data = response.data.data;
      return data;
    }
  } catch (error) {
    console.error(error);
  }
}

// Get tickets from Customer
export async function getCustomerTickets(customerID: string | undefined) {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/customers/${customerID}/receipts`
    );

    if (response.status === 200) {
      const data = response.data.data;
      return data.receipts;
    }
  } catch (error) {
    console.error(error);
  }
}

// Save tickets
export async function sendTicket(
  receiptID: string | undefined,
  object: {
    receipt_id: string | undefined;
    products: Item[];
  }
) {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/receipts/${receiptID}`,
      object
    );
    if (response.status === 200) {
      return true;
    }
  } catch (error: any) {
    console.error(error);
  }
}

// Scan ticket
export async function sendPhoto(
  customerID: string | undefined,
  objectApi: {
    customer_id: string;
    image_id: string;
    image_encoded: string;
  }
) {
  try {
    const response = await axios.post(
      `${API_BASE_URL}/customers/${customerID}/receipts`,
      objectApi
    );
    const { receipt_id, products } = response.data.data;
    localStorage.setItem("ScanProducts", JSON.stringify(products));
    return receipt_id;
  } catch (error) {
    console.error(error);
  }
}

// get data from the ticket including payments
export async function getTicketDetails(ticketID: string | undefined) {
  try {
    const response = await axios.get(`${API_BASE_URL}/receipts/${ticketID}`);

    if (response.status === 200) {
      const data = response.data.data;
      return data;
    }
  } catch (error: any) {
    console.error(error);
  }
}

// get ticket url
export async function getUrlQR(ticketID: string | undefined) {
  try {
    const response = await axios.get(
      `${API_BASE_URL}/receipts/${ticketID}/hash`
    );
    if (response.status === 200) {
      const data = response.data.data;
      return data;
    }
  } catch (error: any) {
    console.error(error);
  }
}

// Login Cognito

export async function GetTokens(email: string, password: string) {
  const body = {
    AuthParameters: {
      USERNAME: email,
      PASSWORD: password,
    },
    AuthFlow: "USER_PASSWORD_AUTH",
    ClientId: `${COGNITO_CLIENT_ID}`,
  };

  const headers = {
    "Content-Type": "application/x-amz-json-1.1",
    "X-Amz-Target": "AWSCognitoIdentityProviderService.InitiateAuth",
  };

  try {
    const response = await axios.post(`${COGNITO_URL}`, body, { headers });
    if (response.status === 200) {
      const { AuthenticationResult } = response.data;
      return AuthenticationResult;
    }
  } catch (error: any) {
    console.error(error);
    if (error.status === 400) {
      return "Revisa los campos que has introducido.";
    } else if (error.status === 401) {
      return "Acceso no autorizado.";
    } else {
      return "Error en el inicio de sesión.";
    }
  }
}

export async function GetCustomerID(email: string, IdToken: string) {
  const body = {
    user_name: email,
  };

  const headers = {
    Authorization: `Bearer ${IdToken}`,
  };

  try {
    const response = await axios.post(`${API_BASE_URL}/authorize`, body, {
      headers,
    });
    if (response.status === 200) {
      const { customer_id: customerID } = response.data.data;
      return customerID;
    }
  } catch (error: any) {
    console.error(error);
    if (error.status === 400) {
      return "Revisa los campos que has introducido.";
    } else if (error.status === 401) {
      return "Acceso no autorizado.";
    } else {
      return "Error en el inicio de sesión.";
    }
  }
}