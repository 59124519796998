import * as React from "react";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import FeedbackIcon from "@mui/icons-material/Feedback";
import mixpanel from "mixpanel-browser";

interface Props {
  page: string;
}

export default function Feedback(props: Props) {
  const [open, setOpen] = React.useState(false);
  const [feedback, setFeedback] = React.useState("");

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    mixpanel.track("Feedback submitted", {
      comment: feedback,
    });
    setOpen(false);
    setFeedback("")
  };

  return (
    <div>
      <Button
        variant="outlined"
        onClick={handleClickOpen}
        style={{
          textTransform: "none",
          fontSize: "1rem",
          fontWeight: "600",
          width: "100%",
          letterSpacing: "0.1rem",
          color: "white",
          borderColor: "white",
        }}
        startIcon={<FeedbackIcon />}
      >
        Feedback
      </Button>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Feedback</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tus comentarios nos ayudarán a mejorar. ¡Gracias!
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Escribe tu comentario"
            type="text"
            value={feedback ? feedback : ""}
            onChange={(e) => setFeedback(e.target.value)}
            multiline
            fullWidth
            variant="standard"
            InputProps={{
              sx: {
                padding: "0.5rem",
              },
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleClose}>Enviar</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
