import React, { useState } from "react";
import "./App.css";
import { Routes, Route } from "react-router-dom";
import TicketValidationPage from "./screens/TicketValidationPage/TicketValidationPage";
import ProfilePage from "./screens/ProfilePage";
import LoginPage from "./screens/LoginPage/LoginPage";
import VerificationCodePage from "./screens/VerificationCodePage";
import JoinPage from "./screens/SignUpPage/SignUpPage";
import ScanTicketPage from "./screens/ScanTicketPage/ScanTicketPage";
import UserWelcomePage from "./screens/UserWelcomePage/UserWelcomePage";
import TicketDetail from "./screens/TicketDetailPage/TicketDetailPage";
import Layout from "./components/commons/Layout/Layout";
import Terminos from "./screens/Terminos";
import PoliticaPrivacidad from "./screens/PoliticaPrivacidad";
import LandingPage from "./screens/LandingPageUser/LandingPage";
import WaitingListPage from "./screens/WaitingListPage";
import PaymentPage from "./screens/PaymentPage/PaymentPage";
import UserSelectionPageWS from "./screens/UserSelectionPageWS/UserSelectionPageWS";
import RequireAuth from "./components/commons/RequireAuth/RequireAuth";
import ForgotPasswordPage from "./screens/ForgotPasswordPage/";
import ResetPasswordPage from "./screens/ResetPasswordPage";
import mixpanel from "mixpanel-browser";

mixpanel.init("6fd1c8ec6173af88d3a9e0375925a968", { debug: true }); 

function App() {

  return (
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<LandingPage />} />
        <Route path="waitlist" element={<WaitingListPage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="join" element={<JoinPage />} />
        <Route path="verification-code" element={<VerificationCodePage />} />
        <Route path="forgot-password" element={<ForgotPasswordPage />} />
        <Route path="reset-password" element={<ResetPasswordPage />} />

        <Route element={<RequireAuth />}>
          <Route path="profile" element={<ProfilePage />} />
          <Route path="profile/ticket-detail/:id" element={<TicketDetail />} />
          <Route path="profile/scan" element={<ScanTicketPage />} />
          <Route
            path="profile/scan/validation/:id"
            element={<TicketValidationPage />}
          />
        </Route>
        <Route path="/welcome/:id" element={<UserWelcomePage />} />
        <Route path="selection/:id" element={<UserSelectionPageWS />} />
        <Route path="payment/:id" element={<PaymentPage />} />
        <Route path="politica-privacidad" element={<PoliticaPrivacidad />} />
        <Route path="terminos-y-condiciones" element={<Terminos />} />
        <Route path="*" element={<h1>Not Found</h1>} />
      </Route>
    </Routes>
  );
}

export default App;