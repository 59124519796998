import React, { useContext, useState } from "react";
import Camera from "react-html5-camera-photo";
import "react-html5-camera-photo/build/css/index.css";
import { Link, useNavigate } from "react-router-dom";
import { themes } from "../../styles/themeStyles";
import { ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import CameraGrid from "../../components/features/CameraGrid/CameraGrid";
import "../../components/styles.css";
import Alert from "@mui/material/Alert";
import { AuthContext } from "../../context/AuthProvider";
import { sendPhoto } from "../../services/api";
import styles from "./styles.module.css";
import mixpanel from "mixpanel-browser";
import Box from "@mui/material/Box";
import LinearProgress from "@mui/material/LinearProgress";

function ScanTicketPage() {
  const { auth } = useContext(AuthContext);
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [isScanning, setIsScanning] = useState(false);

  const handleSubmit = (dataUri: string) => {
    const postPhoto = (dataUri: string) => {
      setError("");
      setIsScanning(true);

      const objectApi = {
        customer_id: `${auth.customerID}`,
        image_id: "testing_id",
        image_encoded: `${dataUri.split(",")[1]}`,
      };

      try {
        sendPhoto(auth.customerID, objectApi).then((receiptID) => {
          if (receiptID) {
            mixpanel.track("Photo taken!");
            navigate(`validation/${receiptID}`);
          } else {
            setError("No se encontró ningún ticket.");
            setIsScanning(false);
            mixpanel.track("Photo not taken, no ReceiptID");
          }
        });
      } catch (e: any) {
        console.error(error);
        setIsScanning(false);
        setError("Algo salió mal, vuelve a tomar la foto.");
        mixpanel.track("Error sending image");
      }
    };

    postPhoto(dataUri);
  };

  return (
    <div className={styles.mainContainer}>
      <div className={styles.loadingContainer}>
        <h2 className={styles.loadingText}>Cargando cámara...</h2>
        <div style={{ position: "relative" }}>
          <CameraGrid />
          <Camera
            onTakePhotoAnimationDone={handleSubmit}
            idealFacingMode="environment"
            isFullscreen={true}
          />
          <div className={styles.absolute}>
            <div className={styles.topElementsContainer}>
              <ThemeProvider theme={themes.ButtonTheme}>
                <Button
                  variant="contained"
                  color="secondary"
                  startIcon={<ArrowBackIosIcon />}
                  style={{
                    textTransform: "none",
                    fontSize: "1rem",
                    fontWeight: "600",
                    letterSpacing: "0.1rem",
                  }}
                  onClick={() => {
                    mixpanel.track("Scan to Profile");
                    navigate("/profile");
                  }}
                >
                  Volver
                </Button>
              </ThemeProvider>
              <div className={styles.dividerS}></div>
              {error && (
                <div className={styles.marginTop}>
                  <Alert severity="error">{error}</Alert>
                </div>
              )}
              {isScanning && (
                <Box sx={{ width: "100%" }}>
                  <p className={styles.scanningText}>Leyendo ticket...</p>
                  <LinearProgress />
                </Box>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default ScanTicketPage;
