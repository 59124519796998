import React, { useEffect, useState } from "react";
import styles from "./styles.module.css";
import SafetyDividerIcon from "@mui/icons-material/SafetyDivider";
import { Selection, SendMessageWS } from "../../../types";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";

interface UserReceiptProps {
  selections: Selection[] | undefined;
  handleChange: (
    itemID: string ,
    selectionMode: string,
    value: number,
    description: string,
    unit_price: string
  ) => void;
  userReady: boolean;
}

const UserReceipt = (props: UserReceiptProps) => {
  const { selections, handleChange, userReady } = props;
  // estado que indica si el user ha seleccionado compartir todo
  const [showSelectionDetail, setShowSelectionDetail] = useState(false);

  const sumTotal = (selections: Selection[] | undefined) => {
    const total = selections?.reduce(
      (acc, curr) => acc + parseFloat(curr.price),
      0
    );
    if (total === undefined) {
      return 0; // Asigna un valor por defecto si total es undefined
    }
    return parseFloat(total.toFixed(2));
  };

  return (
    <div onClick={() => setShowSelectionDetail(!showSelectionDetail)}>
      {selections && selections?.length > 0 && (
        <div className={styles.SelectionCountContainer}>
          <div className={styles.selectionHeader}>
            <h3 className={styles.selectionHeaderText}>Selecciones: </h3>
            <h2 className={styles.selectionHeaderText}>{selections?.length}</h2>
            <h3
              className={styles.selectionHeaderText}
              style={{
                textAlign: "right",
              }}
            >
              Total:{" "}
            </h3>
            <h2
              className={styles.selectionHeaderText}
              style={{
                textAlign: "right",
              }}
            >
              {sumTotal(selections)} €
            </h2>
          </div>
          {showSelectionDetail && (
            <div className={styles.SelectionDetailsContainer}>
              {selections?.map((selection: any) => (
                <div className={styles.itemRow} key={selection.product_id}>
                  {/* {!userReady && (
                    <IconButton
                      aria-label="delete"
                      size="small"
                      onClick={() =>
                        handleChange(
                          selection.product_id,
                          selection.selection_mode,
                          Number(selection.quantity) * -1,
                          selection.description,
                          selection.unit_price
                        )
                      }
                    >
                      <CloseIcon fontSize="small" />
                    </IconButton>
                  )} */}

                  <p className={styles.LateralItemText}>
                    {selection.selection_mode === "unit"
                      ? selection.quantity
                      : `${selection.quantity} / ${selection.users_sharing}`}
                  </p>
                  <p className={styles.itemMiddle}>{selection.description}</p>
                  <p className={styles.LateralItemText}>
                    {selection.selection_mode === "unit"
                      ? Number(selection.price).toFixed(2)
                      : (
                          (Number(selection.unit_price) *
                            Number(selection.quantity)) /
                          Number(selection.users_sharing)
                        ).toFixed(2)}{" "}
                    €
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default UserReceipt;
