import * as React from "react";
import "../assets/css/main.css";
import "../assets/css/noscript.css";
import logo from "../assets/images/bill-logo-white.svg";
import { useNavigate } from "react-router-dom";
import { Button, TextField, ThemeProvider } from "@mui/material";
import {themes} from "../styles/themeStyles";
import { useState } from "react";
import Alert from "@mui/material/Alert";

interface FormValues {
  name: string;
  phone: string;
  email: string;
  businessName: string;
}

const WaitingListPage = () => {
  const [formValues, setFormValues] = useState<FormValues>({
    name: "",
    phone: "",
    email: "",
    businessName: "",
  });

  const navigate = useNavigate();
  const [messageSuccess, setMessageSuccess] = useState("");
  
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    // aquí se puede manejar el envío de datos del formulario
    setMessageSuccess("Has sido apuntado correctamente!");

  };

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <div className="landing is-preload">
        <header id="header" className="alt">
          <div style={{ display: "flex" }}>
            <img
              src={logo}
              alt="Logo"
              style={{
                width: "3rem",
                height: "3rem",
                margin: "1rem",
              }}
            />
          </div>
          {/* <h1>
            <a href="index.html">BiLL</a>
          </h1> */}
          <nav id="nav">
            {/* <ul>
              <li className="special">
                <a href="#menu" className="menuToggle">
                  <span>Menu</span>
                </a>
                <div id="menu">
                  <ul>
                    <li>
                      <a href="index.html">Home</a>
                    </li>
                    <li>
                      <a href="generic.html">Generic</a>
                    </li>
                    <li>
                      <a href="elements.html">Elements</a>
                    </li>
                    <li>
                      <a href="#">Sign Up</a>
                    </li>
                    <li>
                      <a href="#">Log In</a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul> */}
          </nav>
        </header>

        {/* <!-- Main --> */}
        <header>
          <section
            id="main"
            style={{
              background:
                "linear-gradient(54.76deg, #74aee1 2.52%, #660f8c 105.51%)",
            }}
          >
            <header>
              <h2 style={{ color: "#fff" }}>Crea tu cuenta</h2>
              {/* <!-- <p>Completa los campos para dar de alta tu negocio.</p> --> */}
            </header>
            <section className="wrapper style5">
              <div className="inner">
                <section>
                  {messageSuccess && <Alert>{messageSuccess}</Alert>}
                  <h4>¡Apúntate!</h4>
                  <header>
                    <p>
                      Actualmente estamos dando un servicio muy personalizado a
                      nuestros primeros clientes para que puedas disfrutar del
                      mejor producto posible.
                    </p>
                    <p>
                      Apúntate a la lista para recibir cuanto antes acceso a
                      nuestra aplicación.
                    </p>
                  </header>
                  <form onSubmit={handleSubmit}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "1rem",
                      }}
                    >
                      <TextField
                        required
                        name="name"
                        label="Tu nombre"
                        type="text"
                        variant="outlined"
                        value={formValues.name}
                        onChange={handleChange}
                      />
                      <TextField
                        required
                        name="phone"
                        label="Número de teléfono"
                        type="text"
                        variant="outlined"
                        value={formValues.phone}
                        onChange={handleChange}
                      />
                      <TextField
                        required
                        name="email"
                        label="Email"
                        type="email"
                        variant="outlined"
                        value={formValues.email}
                        onChange={handleChange}
                      />
                      <TextField
                        required
                        name="businessName"
                        label="Nombre comercial del negocio"
                        type="text"
                        variant="outlined"
                        value={formValues.businessName}
                        onChange={handleChange}
                      />
                      <div className="buttonContainer">
                        <ThemeProvider theme={themes.ButtonTheme}>
                          <Button
                            variant="contained"
                            color="primary"
                            type="submit"
                            // onClick={handleLogin}
                          >
                            Enviar
                          </Button>
                          <div style={{ margin: "0.5rem" }}></div>
                          <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() =>
                              setFormValues((prevValues) => ({
                                name: "",
                                phone: "",
                                email: "",
                                businessName: "",
                              }))
                            }
                          >
                            Reset
                          </Button>
                        </ThemeProvider>
                      </div>
                    </div>
                  </form>
                  <div style={{ margin: "2rem " }}></div>

                  {/* <h4>Formulario de alta</h4>
                  <header>
                    <p>
                      Completa los campos para dar de alta tu negocio y recibir
                      los pagos en tu cuenta bancaria. Una vez recibamos tus
                      datos, comprobaremos que todo esté correcto y te
                      enviaremos las claves para acceder a la app de BiLL.
                    </p>
                  </header> */}
                  {/* <form method="post" action="#">
                    <div className="row gtr-uniform">
                      <div className="col-6 col-12-xsmall">
                        <input
                          type="text"
                          name="demo-name"
                          id="demo-name"
                          value=""
                          placeholder="Name"
                        />
                      </div>
                      <div className="col-6 col-12-xsmall">
                        <input
                          type="text"
                          name="demo-surname"
                          id="demo-name"
                          value=""
                          placeholder="Surname"
                        />
                      </div>
                      <div className="col-6 col-12-xsmall">
                        <input
                          type="text"
                          name="demo-name"
                          id="demo-name"
                          value=""
                          placeholder="Nombre del negocio"
                        />
                      </div>
                      <div className="col-6 col-12-xsmall">
                        <input
                          type="text"
                          name="demo-name"
                          id="demo-name"
                          value=""
                          placeholder="Dirección"
                        />
                      </div>
                      <div className="col-6 col-12-xsmall">
                        <input
                          type="text"
                          name="demo-name"
                          id="demo-name"
                          value=""
                          placeholder="Código postal"
                        />
                      </div>
                      <div className="col-6 col-12-xsmall">
                        <input
                          type="text"
                          name="demo-name"
                          id="demo-name"
                          value=""
                          placeholder="CIF"
                        />
                      </div>
                      <div className="col-6 col-12-xsmall">
                        <input
                          type="text"
                          name="demo-name"
                          id="demo-name"
                          value=""
                          placeholder="Razón social"
                        />
                      </div>
                      <div className="col-6 col-12-xsmall">
                        <input
                          type="email"
                          name="demo-email"
                          id="demo-email"
                          value=""
                          placeholder="Email"
                        />
                      </div>
                      <div className="col-12">
                        <input
                          type="text"
                          name="demo-name"
                          id="demo-name"
                          value=""
                          placeholder="Cuenta bancaria"
                        />
                      </div>
                      <div className="col-12">
                        <textarea
                          name="demo-message"
                          id="demo-message"
                          placeholder="Enter your message"
                          rows={6}
                        ></textarea>
                      </div>
                      <div className="col-12">
                        <ul className="actions">
                          <li>
                            <input
                              type="submit"
                              value="Send Message"
                              className="primary"
                            />
                          </li>
                          <li>
                            <input type="reset" value="Reset" />
                          </li>
                        </ul>
                      </div>
                    </div>
                  </form> */}
                </section>
              </div>
            </section>
          </section>
        </header>

        <footer id="footer">
          <ul className="copyright">
            <li>&copy; 2023 by YDT DATA CONSULTING SL. All rights reserved.</li>
          </ul>
        </footer>
      </div>
    </div>
  );
};

export default WaitingListPage;
