import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { ThemeProvider } from "@mui/material";
import { themes } from "../../../styles/themeStyles";
import QRCode from "qrcode.react";
import { useParams } from "react-router-dom";
import { FileCopy } from "@mui/icons-material";
import QrCodeIcon from "@mui/icons-material/QrCode";
import styles from "./styles.module.css";
import { useState } from "react";
import logo from "../../../assets/images/logo-background-black.svg";
import mixpanel from "mixpanel-browser";

export default function InviteUser() {
  const { id: hash } = useParams();
  const [open, setOpen] = useState(false);
  const [stateCopyLink, setStateCopyLink] = useState(false);
  const API_BASE_URL = process.env.REACT_APP_API_URL;
  const [urlQR, setUrlQR] = useState(
    `${API_BASE_URL}/welcome/${hash}`
  );

  const handleClickOpen = () => {
    setOpen(true);
    mixpanel.track("Open Invite button Selections");
  };

  const handleClose = () => {
    setOpen(false);
    mixpanel.track("Close Invite button Selections");
  };

  const handleCopyUrl = () => {
    navigator.clipboard.writeText(urlQR);
    mixpanel.track("Link from Selection copied!");
  };

  return (
    <div>
      <ThemeProvider theme={themes.ButtonTheme}>
        <Button
          variant="contained"
          color="secondary"
          style={{
            textTransform: "none",
            fontSize: "1rem",
            fontWeight: "600",
            width: "100%",
            letterSpacing: "0.1rem",
          }}
          onClick={() => {
            handleClickOpen();
          }}
          startIcon={<QrCodeIcon />}
        >
          Invitar usuario
        </Button>
      </ThemeProvider>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle
          id="alert-dialog-title"
          style={{
            fontSize: "1.5rem",
            fontWeight: "bold",
            letterSpacing: "0.1rem",
          }}
        >
          Invitar usuario
        </DialogTitle>
        <DialogContent
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            overflowY: "unset",
          }}
        >
          <div className={styles.QRContainer}>
            <QRCode
              bgColor="#17C3B2"
              fgColor="#080708"
              level="Q"
              value={urlQR}
              renderAs="canvas"
              size={256}
              includeMargin={true}
              className={styles.QR}
            />
            <div className={styles.logoContainer}>
              <img src={logo} alt="Logo" className={styles.logoImg} />
            </div>
          </div>
          <div className={styles.buttonContainer}>
            <ThemeProvider theme={themes.ButtonTheme}>
              <Button
                variant="contained"
                color="secondary"
                startIcon={<FileCopy />}
                onClick={() => {
                  setStateCopyLink(true);
                  handleCopyUrl();
                  setTimeout(() => {
                    setStateCopyLink(false);
                  }, 2000);
                }}
              >
                {stateCopyLink ? "Copiado!" : "Copiar enlace"}
              </Button>
            </ThemeProvider>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleClose}
            style={{
              textTransform: "none",
              fontSize: "1rem",
            }}
            autoFocus
          >
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
