import ProductTable from "../../components/features/ProductTable/ProductTable";
import { Item } from "../../types";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// import { styles } from "../../styles/styles";
import { AuthContext } from "../../context/AuthProvider";
import { themes } from "../../styles/themeStyles";
import { ThemeProvider } from "@mui/material/styles";
import Button from "@mui/material/Button";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import { sendTicket } from "../../services/api";
import styles from "./styles.module.css";
import Logo from "../../components/commons/Logo";
import CircularProgress from "@mui/material/CircularProgress";
import mixpanel from "mixpanel-browser";

const TicketValidationPage = () => {
  const { auth } = useContext(AuthContext);
  const { id: receiptID } = useParams();

  const ScanProducts = JSON.parse(localStorage.getItem("ScanProducts") || "[]");
  const [products, setProducts] = useState<Item[]>(ScanProducts);
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    index: number,
    column: string
  ) => {
    const { value } = event.target;
    mixpanel.track("Edited Item", {
      old_row: products[index],
      column: column,
      new_value: value
    });
    // Update the value of the cell in the component state
    setProducts((prevProducts) => {
      const newProducts = [...prevProducts];
      newProducts[index] = { ...newProducts[index], [column]: value };
      return newProducts;
    });
  };

  const defaultItem: Item = {
    expense_row: `0.0 Producto${products.length + 1} 0.0`,
    description: `Producto${products.length + 1}`,
    price: "0.0",
    quantity: "0.0",
    unit_price: "0.0",
  };

  const SaveTicket = async () => {
    setLoading(true);

    const objectApi = {
      receipt_id: receiptID,
      products: products,
    };

    try {
      const response = await sendTicket(receiptID, objectApi);
      if (response === true) {
        mixpanel.track("Ticket was saved successfully");
        navigate(`/profile/ticket-detail/${receiptID}`);
      }
    } catch (error) {
      console.error(error);
      mixpanel.track("Ticket not saved due to error");
    }
    setLoading(false);
  };

  return (
    <div className={styles.pageContainer}>
      <div className={styles.mainContainer}>
        <Logo />
        {/* header */}
        <div className={styles.titleContainer}>
          <p className={styles.title}>Revisa el ticket</p>
          <p className={styles.selectionSubtitle}>
            Edita los campos en caso de errores y pulsa "Confirmar" para generar
            el código QR.
          </p>
        </div>
        {/* buttons */}
        <div className={styles.editButtonsContainer}>
          <Button
            variant="contained"
            color="info"
            startIcon={<AddIcon />}
            onClick={() => {
              setProducts([defaultItem, ...products]);
              mixpanel.track("Add Item");
            }}
            size="small"
            style={{
              textTransform: "none",
              fontSize: "1rem",
              fontWeight: "400",
              width: "100%",
              letterSpacing: "0.1rem",
            }}
          >
            Añadir item
          </Button>
          <Button
            variant="contained"
            color="error"
            startIcon={<DeleteIcon />}
            onClick={() => {
              setProducts(ScanProducts);
              mixpanel.track("Reset changes");
            }}
            size="small"
            style={{
              textTransform: "none",
              fontSize: "1rem",
              fontWeight: "400",
              width: "100%",
              background: "white",
              color: "gray",
              letterSpacing: "0.1rem",
            }}
          >
            Restaurar
          </Button>
        </div>
        <div className={styles.dividerM}></div>
        <ProductTable products={products} onChange={handleChange} />
        <div className={styles.dividerXXXL}></div>
        <div className={styles.navContainer}>
          {loading ? (
            <div className={styles.LoadingContainer}>
              <CircularProgress size={30} sx={{ color: "#1de9b6" }} />
            </div>
          ) : (
            <div className={styles.navButtonsContainer}>
              <ThemeProvider theme={themes.ButtonTheme}>
                <Button
                  variant="outlined"
                  color="secondary"
                  startIcon={<ArrowBackIosIcon />}
                  style={{
                    textTransform: "none",
                    fontSize: "1rem",
                    fontWeight: "600",
                    width: "100%",
                    letterSpacing: "0.1rem",
                  }}
                  onClick={() => {
                    mixpanel.track("Ticket validation to Scan");
                    navigate("/profile/scan");
                  }}
                >
                  Volver
                </Button>
                <Button
                  variant="contained"
                  color="primary"
                  endIcon={<ArrowForwardIosIcon />}
                  onClick={() => {
                    mixpanel.track("Ticket validation to Ticket Detail");
                    SaveTicket();
                  }}
                  style={{
                    textTransform: "none",
                    fontSize: "1rem",
                    fontWeight: "600",
                    width: "100%",
                    letterSpacing: "0.1rem",
                  }}
                >
                  Confirmar
                </Button>
              </ThemeProvider>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TicketValidationPage;
