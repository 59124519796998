import React, { useContext, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import TicketList from "../../components/features/TicketList/TicketList";
import { Ticket } from "../../types";
import { AuthContext } from "../../context/AuthProvider";
import Button from "@mui/material/Button";
import { PhotoCamera } from "@mui/icons-material";
import { ThemeProvider } from "@mui/material/styles";
import { themes } from "../../styles/themeStyles";
import styles from "./styles.module.css";
import { getCustomerDetails, getCustomerTickets } from "../../services/api";
import Logo from "../../components/commons/Logo";
import CircularProgress from "@mui/material/CircularProgress";
import { useCookies } from "react-cookie";
import mixpanel from "mixpanel-browser";
import { useNavigate } from "react-router-dom";

const ProfilePage = () => {
  const { auth } = useContext(AuthContext);
  const [cookies, setCookie] = useCookies([
    "customerID",
    "name",
    "email",
    "AccessToken",
    "IdToken",
    "RefreshToken",
  ]);
  const [businessName, setBusinessName] = useState<string>(cookies.name ? cookies.name : "");

  // Save tickets from API
  const [tickets, setTickets] = useState<Ticket[]>([]);
  const navigate = useNavigate();

  useEffect(() => {
    if (!cookies.name) {
      try {
        getCustomerDetails(auth.customerID).then((data) => {
          setBusinessName(data.business_name);
          setCookie("name", data.business_name);
          mixpanel.people.set({
            $customerID: auth.customerID,
            $name: data.business_name,
          });
        });
      } catch (error) {
        console.error(error);
      }
    }

    try {
      getCustomerTickets(auth.customerID).then((data) => {
        setTickets(data);
      });
    } catch (error) {
      console.error(error);
    }
  }, []);

  return (
    <div className={styles.pageContainer}>
      <div className={styles.mainContainer}>
        <Logo />
        <div className={styles.businessContainer}>
          <div className={styles.businessTitle}>
            <div className={styles.businessTitleInner}>
              <p className={styles.title}>{businessName}</p>
            </div>
            {/* Si aun no se han cargado los items muestra un loading */}
            {businessName === "" && (
              <div className={styles.LoadingContainer}>
                <CircularProgress size={40} color="inherit" />
              </div>
            )}
          </div>
          <div className={styles.buttonContainer}>
            <ThemeProvider theme={themes.ButtonTheme}>
              <Button
                variant="contained"
                color="primary"
                startIcon={<PhotoCamera />}
                style={{
                  textTransform: "none",
                  fontSize: "1rem",
                  fontWeight: "600",
                  width: "100%",
                  letterSpacing: "0.1rem",
                }}
                onClick={() => {
                  mixpanel.track("Profile to Scan");
                  navigate("scan");
                }}
              >
                Subir ticket
              </Button>
            </ThemeProvider>
          </div>
          <div className={styles.logoSpacing}></div>
          {!tickets && (
            <div className={styles.LoadingContainer}>
              <CircularProgress size={40} color="inherit" />
            </div>
          )}
          <div className={styles.ticketlistContainer}>
            <TicketList tickets={tickets} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfilePage;
