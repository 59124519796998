import { useState } from "react";
import { useNavigate } from "react-router-dom";
import logo from "../../assets/images/bill-logo-white.svg";
import { CircularProgress, ThemeProvider } from "@mui/material";
import Button from "@mui/material/Button";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { themes } from "../../styles/themeStyles";
import styles from "./styles.module.css";
import { Alert } from "@mui/material";

import { forgotPassword } from "../../services/api";
import { useCookies } from "react-cookie";
import FormField from "../../components/commons/FormField";
import Logo from "../../components/commons/Logo";

const ForgotPasswordPage = () => {
  const [cookies, setCookie] = useCookies(["email"]);
  const navigate = useNavigate();
  const [customerEmail, setCustomerEmail] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState(false);

  // Send data to server and save the userId from response
  const handleSubmit = async (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    setLoading(true);
    try {
      const response = await forgotPassword(customerEmail);
      if (response === true) {
        setCookie("email", customerEmail, {
          path: "/",
          maxAge: 3600,
        });

        navigate(`/reset-password`);
      }
    } catch (error) {
      setError("Asegúrate de que has elegido el email correcto.");
    }
    setLoading(false);
  };

  return (
    <div className={styles.ScreenContainer}>
      <Logo />
      <div>
        <h1>Recuperar contraseña</h1>
      </div>
      <div className={styles.InfoContainer}>
        <p className={styles.InfoText}>
          Introduce tu email para enviarte el código para restablecer tu
          contraseña.
        </p>
      </div>
      <div className={styles.marginS}></div>
      {error && (
        <div className={styles.alertContainer}>
          <Alert severity="error">{error}</Alert>
        </div>
      )}
      <div className={styles.loginFieldsContainer}>
        <FormField
          label="Introduce tu email"
          type="email"
          value={customerEmail}
          setValue={setCustomerEmail}
        />
      </div>
      <div className={styles.dividerM} />
      {loading ? (
        <div className={styles.LoadingContainer}>
          <CircularProgress size={30} sx={{ color: "#1de9b6" }} />
        </div>
      ) : (
        <ThemeProvider theme={themes.ButtonTheme}>
          <Button
            variant="contained"
            color="primary"
            endIcon={<ArrowForwardIosIcon />}
            onClick={handleSubmit}
          >
            Recuperar contraseña
          </Button>
        </ThemeProvider>
      )}
      <div className={styles.dividerL} />
    </div>
  );
};

export default ForgotPasswordPage;
