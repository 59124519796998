import React, { useState, createContext, useEffect } from "react";
import { useCookies } from "react-cookie";

interface AuthContextType {
  auth: any;
  setAuth: (auth: any) => void;
}

export const AuthContext = createContext<AuthContextType>({
  auth: {},
  setAuth: () => {},
});

interface AuthProviderProps {
  children: React.ReactNode;
}

export const AuthProvider = ({ children }: AuthProviderProps) => {
  const [cookies] = useCookies([
    "customerID",
    "email",
    "name",
    "AccessToken",
    "IdToken",
    "RefreshToken",
  ]);
  const [auth, setAuth] = useState({ customerID: cookies.customerID });

  // useEffect(() => {
  //   if (cookies.customerID) {
  //     setAuth({ customerID: cookies.customerID });
  //   }
  // }, [cookies]);

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};
