import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Selection,
  SendMessageWS,
  UserTicket,
  WebSocketItems,
} from "../../types";
import styles from "./styles.module.css";
import { ThemeProvider } from "@mui/material/styles";
import { themes } from "../../styles/themeStyles";
import Button from "@mui/material/Button";
import "../../components/styles.css";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import InviteUser from "../../components/features/InviteUser/InviteUser";
import WebSocketService from "../../services/WebSocket";
import EditIcon from "@mui/icons-material/Edit";
import { getTicketProducts } from "../../services/api";
import { useCookies } from "react-cookie";
import UserReceipt from "../../components/features/UserReceipt";
import ItemSelection from "../../components/features/ItemSelection";
import Logo from "../../components/commons/Logo";
import mixpanel from "mixpanel-browser";
import Feedback from "../../components/features/Feedback/Feedback";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const UserSelectionPageWS = () => {
  const { id: hash } = useParams();
  const navigate = useNavigate();

  const [cookies, setCookie] = useCookies([
    "customerID",
    "name",
    "email",
    "AccessToken",
    "IdToken",
    "RefreshToken",
    "userID",
    "receiptID",
  ]);
  const userID = cookies.userID;
  const userName = cookies.name;

  // Items del ticket que restan por seleccionar
  const [items, setItems] = useState<WebSocketItems[]>([]);

  // Items del ticket con quantity > 0 o selecciones del user
  // lo descarto porque resulta incomodo para el usuario que los items se vayan moviendo todo el rato
  // const [listedItems, setListedItems] = useState<WebSocketItems[]>([]);

  // Users que han hecho alguna selección
  const [users, setUsers] = useState<UserTicket[]>([]);

  // Selecciones del usuario
  const [userSelections, setUserSelections] = useState<Selection[]>([]);

  const [selectionError, setSelectionError] = useState("");

  // estado que indica si el user ha seleccionado compartir todo
  const [fullSharedChecked, setFullSharedChecked] = useState<boolean>(false);

  // estado que indica si el user ha confirmado su selección
  const [userReady, setUserReady] = useState(false);

  // estado que indica si el user ha confirmado su selección
  const [ticketReady, setTicketReady] = useState(false);

  // get items from ticket
  useEffect(() => {
    getTicketProducts(hash, setItems);
  }, []);

  useEffect(() => {
    const user = users?.filter((user) => user.user_id === userID);
    const user_selections = user[0]?.selections;
    if (user_selections && user_selections?.length > 0) {
      setUserSelections(user_selections);
      const receiptID = user[0].receipt_id.replace("receipt#", "");
      setCookie("receiptID", receiptID);
    } else setUserSelections([]);
  }, [users]);

  const getNamesOfUnreadyUsers = (array: any) => {
    const names = array
      .filter((obj: any) => obj.is_ready !== true)
      .map((obj: any) => obj.user_name);
    return names.join(", ");
  };

  // Funcion que se encarga de gestionar los elementos de Selections
  // y de enviar los mensajes al websocket
  const handleChange = (
    itemID: string | undefined,
    selectionMode: string,
    value: number,
    description: string,
    unit_price: string
  ) => {
    // crea el mensaje a enviar al websocket
    const message = {
      action: "send_message",
      data: {
        hash_url: `${hash}`,
        selection: {
          description: description,
          unit_price: unit_price,
          quantity: `${value}`,
          product_id: itemID,
          user_id: userID,
          selection_mode: selectionMode,
        },
        user: {
          user_id: userID,
          is_ready: false,
        },
      },
    };
    mixpanel.track("Selección enviada", {
      selection: message,
    });
    sendMessage(message);
  };

  const ReadyButton = () => {
    return (
      <Button
        disabled={userSelections?.length === 0}
        variant={userReady ? "outlined" : "contained"}
        color={userReady ? "secondary" : "primary"}
        startIcon={userReady && <EditIcon />}
        style={{
          textTransform: "none",
          fontSize: "1rem",
          fontWeight: "600",
          width: "100%",
          letterSpacing: "0.1rem",
        }}
        onClick={() => {
          const state = !userReady;
          setUserReady(!userReady);
          const message = {
            action: "send_message",
            data: {
              hash_url: `${hash}`,
              selection: {
                description: null,
                unit_price: null,
                quantity: null,
                product_id: null,
                user_id: null,
                selection_mode: null,
              },
              user: {
                user_id: `${userID}`,
                is_ready: state,
              },
            },
          };
          mixpanel.track("User readiness changed", {
            isready: state,
          });
          sendMessage(message);
        }}
      >
        {userReady === false ? "Listo!" : "Editar"}
      </Button>
    );
  };

  // Websocket ///////////////////////////////////////////////////////
  const [webSocket, setWebSocket] = useState<WebSocket | null>(null);

  useEffect(() => {
    const WebSocketProps = {
      hash: hash,
      userID: userID,
      setSelectionError: setSelectionError,
      setItems: setItems,
      setUsers: setUsers,
      setWebSocket: setWebSocket,
      setTicketReady: setTicketReady,
    };

    WebSocketService(WebSocketProps);
  }, []);

  const sendMessage = (message: any) => {
    if (webSocket) {
      webSocket.send(JSON.stringify(message));
    }
  };

  return (
    <div className={styles.ScreenContainer}>
      <Logo />
      <div className={styles.dividerM}></div>

      <div className={styles.contentContainer}>
        <div>
          {/* ReadyContainer */}
          {userReady && (
            <div>
              <div className={styles.topButtonsBox}>
                <Feedback page="selections" />
              </div>
              <div className={styles.dividerXL}></div>
              <div
                className={styles.ReadyContainer}
                onClick={() => navigate(`/payment/${hash}`)}
              >
                {/* Enseña el container de pagar, si no hay 
                selections shared le dejo ir a pagar */}
                <div className={styles.ReadyElementsContainer}>
                  {!ticketReady ? (
                    <div className={styles.ReadyContent}>
                      <CircularProgress size={25} color="inherit" />
                      <p className={styles.selectionSubtitle}>
                        Espera a <b>{getNamesOfUnreadyUsers(users)}</b> para
                        obtener tu importe definitivo.
                      </p>
                    </div>
                  ) : (
                    <div className={styles.ReadyContent}>
                      <p className={styles.selectionSubtitle}>
                        Haz click en Pagar para ir a la siguiente pantalla.
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className={styles.dividerXL} />
              <p className={styles.selectionSubtitle}>Items sin seleccionar:</p>
              <div className={styles.dividerM} />
              {items
                ?.filter((item: any) => Number(item.quantity) > 0)
                .map((item: any) => (
                  <div className={styles.contentBox} key={item.product_id}>
                    <ItemSelection
                      item={item}
                      handleChange={handleChange}
                      userReady={userReady}
                      currentSelection={[]}
                    />
                  </div>
                ))}
            </div>
          )}

          {/* Muestra los items */}
          {userReady === false && (
            <div className={styles.InfoContainer}>
              <div className={styles.topButtonsBox}>
                {/* header */}
                <InviteUser />
                {/* <Feedback page="selections" /> */}
              </div>
              <div className={styles.dividerM}></div>
              <div className={styles.titleContainer}>
                <p className={styles.title}>Hola {userName}!</p>
                <p className={styles.selectionSubtitle}>
                  Marca los productos del ticket que quieras pagar y
                  calcularemos el total.
                </p>
              </div>
              <div className={styles.dividerXL} />
              {/* Enseña el mensaje de error si lo hay */}
              {selectionError && (
                <div style={{ width: "100%" }}>
                  <div className={styles.dividerXL} />
                  <Alert severity="error">
                    ¡Edita tu selección! {selectionError}
                  </Alert>
                  <div className={styles.dividerXL} />
                </div>
              )}
              {/* Si aun no se han cargado los items muestra un loading */}
              {items.length === 0 && (
                <div className={styles.LoadingContainer}>
                  <CircularProgress size={40} color="inherit" />
                </div>
              )}
              {/* Muestra los items */}
              {items?.map((item: any) => (
                <div className={styles.contentBox} key={item.product_id}>
                  <ItemSelection
                    item={item}
                    handleChange={handleChange}
                    userReady={userReady}
                    currentSelection={userSelections?.filter(
                      (selection: any) =>
                        selection.product_id === item.product_id
                    )}
                  />
                </div>
              ))}
            </div>
          )}

          <div className={styles.dividerXXXL} />
        </div>
      </div>

      {/* Footer */}
      <div className={styles.FooterContainer}>
        <div className={styles.SelectionFooter}>
          <UserReceipt
            selections={userSelections}
            handleChange={handleChange}
            userReady={userReady}
          />
          <div className={styles.dividerM}></div>
          <div className={styles.buttonContainer}>
            <ThemeProvider theme={themes.ButtonTheme}>
              {ReadyButton()}
              {userReady === true && (
                <Button
                  disabled={!ticketReady}
                  variant="contained"
                  color="primary"
                  style={{
                    textTransform: "none",
                    fontSize: "1rem",
                    fontWeight: "600",
                    width: "100%",
                    letterSpacing: "0.1rem",
                  }}
                  endIcon={<ArrowForwardIosIcon />}
                  onClick={() => {
                    mixpanel.track("User clicked on pay button");
                    navigate(`/payment/${hash}`);
                  }}
                >
                  Siguiente
                </Button>
              )}
            </ThemeProvider>
          </div>
        </div>
      </div>
      <div className={styles.MarginBottom}></div>
    </div>
  );
};

export default UserSelectionPageWS;
