import * as React from "react";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";


interface CounterProps {
  value: number;
  maxValue: string;
  onChange: React.Dispatch<React.SetStateAction<number>>;
  stepSize: number;
}

const QuantityCounter = (props: CounterProps) => {

  const { value, maxValue, onChange, stepSize } = props;
  const maxValueNumber = Number(maxValue);

  const decrementCount = () => {
    const roundedStepSize = +stepSize.toFixed(2);
    const newValue = Math.max(value - roundedStepSize, roundedStepSize);
    const newValue2 = +newValue.toFixed(2);
    onChange(newValue2);
  };

  const incrementCount = () => {
    const roundedStepSize = +stepSize.toFixed(2);
    const newValue = Math.min(value + roundedStepSize, maxValueNumber);
    const newValue2 = +newValue.toFixed(2);
    onChange(newValue2);
  };

  return (
    <div style={{ display: "flex", justifyContent: "center" }}>
      <Button aria-label="reduce" onClick={decrementCount}>
        <RemoveIcon fontSize="small" />
      </Button>
      <div
        // style={{
        //   border: "1px solid #c4c4c4",
        //   borderRadius: "0.25rem",
        //   textAlign: "center",
        //   minWidth: "4rem",
        // }}
        style={{
          border: "1px solid #c4c4c4",
          borderRadius: "0.25rem",
          display: "flex",
          alignItems: "center",
          padding: "0.25rem 1rem"
        }}
      >
        <p
          style={{
            margin: 0
          }}
        >
          {value}
        </p>
      </div>
      <Button aria-label="increase" onClick={incrementCount}>
        <AddIcon fontSize="small" />
      </Button>
    </div>
  );
};

export default QuantityCounter;