import { Suspense } from "react";
import { Outlet } from "react-router-dom";
import { Container } from "@material-ui/core";
import ScrollToTop from "../ScrollToTop/ScrollToTop";

const Layout = () => {
  return (
    <Suspense fallback={<span>Loading content...</span>}>
      <Container style={{ height: "100%", margin: 0, padding: 0 }}>
        <ScrollToTop />
        <Outlet />
      </Container>
    </Suspense>
  );
};

export default Layout;
